import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { memo } from 'react';
import { ChevronRight } from 'react-feather';
import { v4 } from 'uuid';
import { PageHeaderProps, PagePathProps } from './PageHeader.types';

const PageHeader = ({ path, subheading, element }: PageHeaderProps) => {
  return (
    <HStack
      width="100%"
      background="white"
      rounded="2px"
      padding={5}
      alignItems={{ base: 'left', md: 'center' }}
      justifyContent="space-between"
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <VStack alignItems="flex-start" spacing={1}>
        <Breadcrumb
          fontWeight={600}
          fontSize="16px"
          separator={<ChevronRight size={15} />}
          spacing={1}
        >
          {path?.map((item: PagePathProps, index) => {
            return item?.onClick ? (
              <BreadcrumbItem key={v4()}>
                <Text
                  cursor="pointer"
                  onClick={item?.onClick}
                  opacity={index == path.length - 1 ? 1 : 0.8}
                  _hover={{
                    textDecoration: 'underline',
                  }}
                >
                  {item?.label.toUpperCase()}
                </Text>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem key={v4()}>
                <BreadcrumbLink
                  href={item?.link}
                  opacity={index == path.length - 1 ? 1 : 0.8}
                >
                  {item?.label.toUpperCase()}
                </BreadcrumbLink>
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
        <Text fontSize="14px" opacity={0.6}>
          {subheading}
        </Text>
      </VStack>
      {element}
    </HStack>
  );
};

const memoizePageHeader = memo(PageHeader);
export { memoizePageHeader as PageHeader };
