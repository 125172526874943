import axios, { AxiosRequestConfig } from 'axios';
import { SessionHandler } from '../SessionHandler';
import ApiConnection from '../connection';
import { LoginForm } from './Auth.type';

export const authentication = async (formData: LoginForm) => {
  const axiosConfig: AxiosRequestConfig = {
    method: 'post',
    url: '/auth/login',
    params: formData,
  };

  return await ApiConnection(axiosConfig);
};

export const getUserInformation = async () => {
  const token = SessionHandler.getToken();
  const axiosConfig: AxiosRequestConfig = {
    method: 'get',
    url: '/auth/me',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await ApiConnection(axiosConfig);
};

export const requestLogout = async () => {
  const token = SessionHandler.getToken();
  const axiosConfig: AxiosRequestConfig = {
    method: 'get',
    url: '/auth/logout',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await ApiConnection(axiosConfig);
};

export const passportCallback = async (code: string) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_PASSPORT_URL}/oauth/token`,
      {
        grant_type: 'authorization_code',
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
        redirect_uri: import.meta.env.VITE_CLIENT_CALLBACK,
        code: code,
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    return null;
  }
};

export const passportValidation = async (passportToken: string) => {
  const axiosConfig: AxiosRequestConfig = {
    method: 'get',
    url: '/auth/passport-validation',
    headers: {
      Authorization: `Bearer ${passportToken}`,
      clientId: import.meta.env.VITE_CLIENT_ID,
    },
  };
  return await ApiConnection(axiosConfig);
};
