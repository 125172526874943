import { Button, Flex } from '@chakra-ui/react';
import { PageHeader } from '@components/common';
import { PagePathProps } from '@components/common/PageHeader/PageHeader.types';
import { AttacklistContext } from '@context/AttacklistContext';
import { attacklistPages } from '@context/AttacklistContext/Attacklist.types';
import { AttacklistDataModel } from '@models/AttacklistModel';
import { downloadGuideline } from '@utils/api';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BookOpen } from 'react-feather';
import { Route, Routes, useNavigate } from 'react-router-dom';

const SA = () => {
  const [show, setShow] = useState<'Table' | 'Followup'>('Table');
  const [row, setRow] = useState<AttacklistDataModel | null>(null);

  const navigate = useNavigate();

  const setShowContext = useCallback(
    (pages: attacklistPages) => {
      setShow(pages);
      pages == 'Table' ? navigate('/sa/retention') : navigate('/sa/followup');
    },
    [navigate]
  );

  const initialPath: PagePathProps[] = useMemo(
    () => [
      {
        label: 'SA',
        onClick: () => setShowContext('Table'),
      },
      {
        label: 'Retention',
        onClick: () => setShowContext('Table'),
      },
    ],
    [setShowContext]
  );

  const [path, setPath] = useState<PagePathProps[]>(initialPath);

  useEffect(() => {
    show != 'Followup' && setPath(initialPath);
  }, [initialPath, path, show]);

  // -- Dynamic Import
  const AttacklistTable = lazy(() =>
    import('@features/MRS/Attacklist').then((module) => ({
      default: module.AttacklistTable,
    }))
  );

  const Followup = lazy(() =>
    import('@features/MRS/Attacklist').then((module) => ({
      default: module.Followup,
    }))
  );

  return (
    <Flex width="100%" flexDirection="column" rowGap="10px">
      <PageHeader
        subheading="Attacklist Generated by MRS Genius"
        path={path}
        element={
          <Button
            variant="whitegrey"
            fontSize="small"
            leftIcon={<BookOpen size={15} />}
            onClick={() => downloadGuideline()}
          >
            Read Guideline
          </Button>
        }
      />

      <AttacklistContext.Provider
        value={{
          parent: 'MRS',
          path: path,
          show: show,
          row: row,
          setShow: (current) => setShowContext(current),
          setRows: (data) => setRow(data),
          setPath: (path) => setPath(path),
        }}
      >
        <Routes>
          <Route
            path="/attacklist"
            element={
              <AnimatePresence>
                <motion.div
                  key="table"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{
                    opacity: 0,
                    x: -20,
                  }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                >
                  <Suspense>
                    <AttacklistTable attacklist_type="Service Advisor" />
                  </Suspense>
                </motion.div>
              </AnimatePresence>
            }
          />

          <Route
            path="/followup"
            element={
              <AnimatePresence>
                <motion.div
                  key="followup"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{
                    opacity: 0,
                    x: -20,
                  }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                >
                  <Suspense>
                    <Followup is_updateable />
                  </Suspense>
                </motion.div>
              </AnimatePresence>
            }
          />
        </Routes>
      </AttacklistContext.Provider>
    </Flex>
  );
};

export default SA;
