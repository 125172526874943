import { extendTheme } from '@chakra-ui/react';

const Theme = extendTheme({
  fonts: {
    heading: `'Source Sans Pro', sans-serif`,
    body: `'Source Sans Pro', sans-serif`,
  },

  colors: {
    softblue: '#F1F5F9',
    primary: '#E55254',
    secondary: '#0E93A5',
    hover: '#f2f2f2',

    success: 'linear-gradient(90deg, #D4F2FE, #BEFEF0)',
    warning: 'linear-gradient(90deg, #F8FCCC, #FDF1D6)',
    danger: 'linear-gradient(90deg, #FFE4E2, #FFEDDF)',

    freshGreen: '#00D36E',
    fontColor: '#101010',

    subHeading: '#434343',
    glassmorpishm:
      'linear-gradient(90deg, rgba(255,255,255,0.7), rgba(255,255,255,0.6))',

    tableHeader:
      'linear-gradient(180deg, rgb(255,255,255,0), rgb(237,237,237))',
    linearBlack: 'linear-gradient(90deg, rgb(124,124,124), rgb(106,106,106))',
    smoothBorder: '#DEDEDE',
  },

  styles: {
    global: {
      body: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      heading: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
    },
  },

  // -- custom variant
  components: {
    Button: {
      variants: {
        whitegrey: {
          bg: 'linear-gradient(90deg, #FFFFFF, #FAFAFA)',
          border: '1px solid #E1E1E1',
          color: '#5A5A5A',
          borderRadius: '2px',
          fontWeight: '500',
          _hover: {
            bg: 'hover',
          },
        },
      },
    },

    Input: {
      variants: {
        readonly: {
          background: 'black',
        },
      },
    },
  },
});

export default Theme;
