import { AccordionPanel, Flex, Text } from '@chakra-ui/react';
import { useSidebar } from '@context/index';
import { getMenuFromURL, isTablet } from '@utils/helper';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import MenuDTO from '../Menu/MenuDTO';

interface ChildType {
  menu: MenuDTO[];
}

const NavChild = ({ menu }: ChildType) => {
  const navigate = useNavigate();
  const { setActiveMenu, toggleSidebar } = useSidebar();

  const onNavClick = (link: string) => {
    setActiveMenu(getMenuFromURL(link ?? ''));
    navigate(link);
    if (isTablet()) toggleSidebar();
  };

  return (
    <AccordionPanel
      display="flex"
      flexDirection="column"
      marginLeft="20px"
      borderLeft="1px solid #f2f2f2"
      padding={0}
    >
      {menu.map((item, index) => {
        const NavIcon = item?.icon;

        return index === 0 ? null : (
          <Flex
            as="button"
            key={v4()}
            alignItems="center"
            columnGap="15px"
            padding="8px 20px"
            onClick={() => onNavClick(item?.link ?? '')}
            _hover={{
              backgroundColor: 'hover',
            }}
          >
            <NavIcon size="15px" />
            <Text fontSize="15px">{item?.label}</Text>
          </Flex>
        );
      })}
    </AccordionPanel>
  );
};

// Memoize the exported component
const MemoizedNavChild = memo(NavChild);

export { MemoizedNavChild as NavChild };
