// Layout.tsx
import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { Sidebar, Topbar } from '..';
import { minimumTabletWidth } from '../../../config/size';
import { SidebarContext } from '../../../context';
import { LayoutInterface } from './Layout.types';

export const Layout = ({ children }: LayoutInterface) => {
  // -- Sidebar Context Handler @faidfadjri
  const [isOpen, setIsOpen] = useState<boolean>(
    window.innerWidth > minimumTabletWidth
  );
  const [activeMenu, setActiveMenu] = useState(() => {
    const URL = window.location.pathname;

    const segments = URL.split('/');
    const cleanedURL = segments[1] || 'Overview';
    return cleanedURL;
  });

  const onToggleSidebar = useCallback(() => {
    setIsOpen((current) => !current);
  }, []);

  const setActive = useCallback((menu: string) => {
    setActiveMenu(menu);
  }, []);

  // -- End Sidebar Context Handler

  return (
    <SidebarContext.Provider
      value={{
        isOpen: isOpen,
        toggleSidebar: onToggleSidebar,
        activeMenu: activeMenu,
        setActiveMenu: setActive,
      }}
    >
      <Flex minHeight="100vh" direction="column" position="relative">
        <Topbar />
        <Flex flexGrow={1} position="relative">
          <Sidebar active={activeMenu} />
          <Box
            flexGrow={1}
            background="softblue"
            padding={5}
            overflowX="hidden"
          >
            {children}
          </Box>
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  );
};
