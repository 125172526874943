import { createContext, useContext } from 'react';
import { AttacklistContextProps } from './Attacklist.types';

// Define the context
export const AttacklistContext = createContext<AttacklistContextProps>({
  show: 'Table',
  setShow() {},
  row: null,
  setRows() {},
  path: [],
  setPath() {},
  parent: 'MRS',
});

// Define the hook
export function useAttacklist() {
  const context = useContext(AttacklistContext);

  if (!context) {
    throw new Error(
      'useAttacklist must wrapped using AttacklistContext Provider'
    );
  }

  return context;
}
