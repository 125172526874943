import { AnimatePresence, motion } from 'framer-motion';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout, SlideDown } from './components';
import './config/style.css';

import '@fontsource/source-sans-pro'; // Defaults to weight 400
import '@fontsource/source-sans-pro/400-italic.css'; // Specify weight and style
import '@fontsource/source-sans-pro/400.css'; // Specify weight
import MRS from './pages/MRS';
import SA from './pages/SA';
import WithAuth from './utils/hoc/WithAuth/WithAuth';

const App = () => {
  const Dashboard = lazy(() => import('./pages/Dashboard'));
  const Report = lazy(() => import('./pages/MRS/Report'));
  const Reasons = lazy(() => import('./pages/MRS/Reasons'));
  const Retention = lazy(() => import('./pages/SA/Retention'));

  // Databases
  const Customer = lazy(() => import('./pages/Database/Customer'));
  const Vehicle = lazy(() => import('./pages/Database/Vehicle'));
  const Services = lazy(() => import('./pages/Database/Services'));

  // Error Pages
  const NotFound = lazy(() => import('./pages/Error/NotFound'));

  return (
    <AnimatePresence>
      <Layout key="app-wrapper">
        <Routes>
          <Route
            path="/"
            element={
              <motion.div
                key="dashboard-pages"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <Suspense>
                  <Dashboard />
                </Suspense>
              </motion.div>
            }
          />
          <Route path="/mrs">
            <Route path="/mrs/*" element={<MRS />}></Route>

            <Route
              path="/mrs/reason"
              element={
                <motion.div
                  key="reason-pages"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <Suspense>
                    <Reasons />
                  </Suspense>
                </motion.div>
              }
            />
            <Route
              path="/mrs/report"
              element={
                <motion.div
                  key="report-pages"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <Suspense>
                    <Report />
                  </Suspense>
                </motion.div>
              }
            />
          </Route>

          {/* Database */}

          <Route path="/database">
            <Route
              path="/database/customer"
              element={
                <SlideDown key="customer" suspense>
                  <Customer />
                </SlideDown>
              }
            />

            <Route
              path="/database/vehicle"
              element={
                <SlideDown key="vehicle" suspense>
                  <Vehicle />
                </SlideDown>
              }
            />

            <Route
              path="/database/services"
              element={
                <SlideDown key="services" suspense>
                  <Services />
                </SlideDown>
              }
            />
          </Route>

          <Route path="sa">
            <Route path="/sa/*" element={<SA />}></Route>
            <Route
              path="/sa/retention"
              element={
                <SlideDown key="Retention" suspense>
                  <Retention />
                </SlideDown>
              }
            />
          </Route>

          <Route
            path="*"
            element={
              <SlideDown key="404" suspense>
                <NotFound />
              </SlideDown>
            }
          />
        </Routes>
      </Layout>
    </AnimatePresence>
  );
};

export default WithAuth(App);
