import { minimumMobileWidth, minimumTabletWidth } from '../../../config';

export const isMobile = (mobileWidth: number = minimumMobileWidth): boolean => {
  const isMobile = window.innerWidth <= mobileWidth;
  return isMobile;
};

export const isTablet = (mobileWidth: number = minimumTabletWidth): boolean => {
  const isTablet = window.innerWidth <= mobileWidth;
  return isTablet;
};
