import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Callback from '../pages/Auth/Callback';
import Login from '../pages/Auth/Login';
import { Redirect } from '../pages/Auth/Redirect';

//--- Authentication
const routes = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '/*',
    element: <App />,
  },
  {
    path: 'auth/callback',
    element: <Callback />,
  },
  {
    path: 'auth/redirect',
    element: <Redirect />,
  },
];

export const router = createBrowserRouter(routes);
