import React from 'react';
import { getUserInformation } from '../../api/Auth';
import { SessionHandler } from '../../api/SessionHandler';
interface AdditionalProps {
  additionalProp: string;
}

const WithAuth =
  <P extends object>(
    WrappedComponent: React.ComponentType<P & AdditionalProps>
  ) =>
  (props: Omit<P, keyof AdditionalProps>) => {
    const additionalPropValue = 'This is an additional prop';

    const token = SessionHandler.getToken();
    let loading = true;
    if (!token) {
      window.location.href = '/login';
      return null;
    } else {
      getUserInformation()
        .catch(() => {
          SessionHandler.clearLocalStorage();
          window.location.href = '/login';
        })
        .finally(() => (loading = false));
    }

    if (!loading) return null;

    return (
      <WrappedComponent
        {...(props as P)}
        additionalProp={additionalPropValue}
      />
    );
  };

export default WithAuth;
