import {
  BarChart,
  Database,
  FastForward,
  Folder,
  Grid,
  HardDrive,
  Headphones,
  Icon,
  Layers,
  MessageCircle,
  PhoneForwarded,
  Pocket,
  Settings,
  Tool,
  Users,
} from 'react-feather';
import MenuDTO from './MenuDTO';

export type MenuItemType = {
  label?: string;
  icon?: Icon;
  link?: string | null;
  isExpanded?: boolean;
  group?: string;
};

export const Menu: MenuDTO[][] = [
  [
    {
      label: 'Overview',
      icon: Grid,
      link: '/',
      isExpanded: false,
      group: 'Overview',
    },
  ],
  [
    {
      label: 'MRS',
      icon: Headphones,
      link: null,
      isExpanded: true,
      group: 'MRS',
    },
    {
      label: 'Attacklist',
      icon: PhoneForwarded,
      link: '/mrs/attacklist',
      isExpanded: false,
      group: 'MRS',
    },
    {
      label: 'Reason',
      icon: Layers,
      link: '/mrs/reason',
      isExpanded: false,
      group: 'MRS',
    },
    {
      label: 'Report',
      icon: Folder,
      link: '/mrs/report',
      isExpanded: false,
      group: 'MRS',
    },
  ],
  [
    {
      label: 'Database',
      icon: Database,
      link: null,
      isExpanded: true,
      group: 'Database',
    },
    {
      label: 'Customers',
      icon: Users,
      link: '/database/customer',
      isExpanded: false,
      group: 'Database',
    },
    {
      label: 'Vehicles',
      icon: HardDrive,
      link: '/database/vehicle',
      isExpanded: false,
      group: 'Database',
    },
    {
      label: 'Services',
      icon: Tool,
      link: '/database/services',
      isExpanded: false,
      group: 'Database',
    },
  ],
  [
    {
      label: 'SA',
      icon: Pocket,
      link: null,
      isExpanded: true,
      group: 'SA',
    },
    {
      label: 'Retention',
      icon: FastForward,
      link: '/sa/retention',
      isExpanded: false,
      group: 'SA',
    },
  ],
  [
    {
      label: 'Settings',
      icon: Settings,
      link: null,
      isExpanded: true,
      group: 'Setting',
    },

    {
      label: 'Broadcast',
      icon: MessageCircle,
      link: null,
      isExpanded: false,
      group: 'Setting',
    },

    {
      label: 'Log',
      icon: BarChart,
      link: null,
      isExpanded: false,
      group: 'Setting',
    },
  ],
];

export const getMenu = (userRole: string): MenuDTO[][] => {
  const allowedGroups: Record<string, string[]> = {
    SPV: ['Overview', 'MRS', 'Database', 'SA'],
    MRA: ['Overview', 'MRS'],
    SA: ['Overview', 'SA'],
  };

  const filteredMenu = Menu.filter((group) => {
    const groupLabel = group[0]?.group || '';
    return allowedGroups[userRole]?.includes(groupLabel);
  });

  return filteredMenu;
};
