import { useEffect } from 'react';
export const Redirect = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams({
      client_id: import.meta.env.VITE_CLIENT_ID,
      redirect_uri: import.meta.env.VITE_CLIENT_CALLBACK,
      response_type: 'code',
      scope: '*',
    });

    const queryString = queryParams.toString();
    const authorizationUrl = `${
      import.meta.env.VITE_PASSPORT_URL
    }/oauth/authorize?${queryString}`;

    window.location.href = authorizationUrl;
  }, []);

  return null;
};
