import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import { Lock, UserCheck } from "react-feather";

import { Link, useNavigate } from "react-router-dom";
import { UserModel } from "../../models/UserModel";
import { authentication } from "../../utils/api/Auth";
import { LoginForm } from "../../utils/api/Auth/Auth.type";
import { SessionHandler } from "../../utils/api/SessionHandler";

export interface AuthResponse {
  token: string;
  user: UserModel;
  message?: string;
}

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<LoginForm>({
    username: "",
    password: "",
  });

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    },
    []
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    authentication(formData)
      .then((res: AxiosResponse) => {
        toast({
          title: "Congratulations",
          description: res?.data?.message,
          status: "success",
          isClosable: true,
          variant: "subtle",
          position: "bottom-right",
          duration: 1000,
        });

        // Save to localstorage
        const response: AuthResponse = res.data;
        localStorage.setItem("token", response.token);
        SessionHandler.setUser(response.user);
        SessionHandler.setToken(response.token);

        // Check if user and token are set in local storage
        if (SessionHandler.getUser() && SessionHandler.getToken()) {
          navigate("/");
        } else {
          // Handle the case when user or token is not set
          toast({
            title: "Session data missing",
            description: "User or token data not saved properly.",
            status: "error",
            isClosable: true,
            variant: "subtle",
            position: "bottom-right",
            duration: 1000,
          });
        }
      })
      .catch((err) => {
        toast({
          title: "Something went wrong",
          description: err.response?.data?.message,
          status: "error",
          isClosable: true,
          variant: "subtle",
          position: "bottom-right",
          duration: 1000,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Flex
      direction="column"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      background="softblue"
    >
      <VStack
        alignItems="flex-start"
        background="white"
        width={{ base: "90%", md: "60%", lg: "30%" }}
        height={{ base: "80%", lg: "70%" }}
        padding={10}
      >
        <Flex display="flex" columnGap="10px">
          <Image src="/images/logo.svg" />
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            MRSGENIUS
          </Text>
        </Flex>
        <Divider marginY="10px" />
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <VStack width="100%" spacing={4}>
            <FormControl>
              <FormLabel fontSize="14px" opacity={0.7}>
                Username
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <UserCheck size={15} />
                </InputLeftElement>
                <Input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  placeholder="Enter your username"
                  required
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel fontSize="14px" opacity={0.7}>
                Password
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Lock size={15} />
                </InputLeftElement>
                <Input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter your password"
                  required
                />
                <InputRightElement width="3rem"></InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              background="primary"
              color="white"
              borderRadius="2px"
              width="100%"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Login
            </Button>

            <Box
              width="100%"
              textAlign="center"
              position="relative"
              marginY="8px"
            >
              <Divider borderColor="gray.300" />
              <Text
                position="absolute"
                top="-2"
                backgroundColor="white"
                px="2"
                fontSize="sm"
                color="gray.500"
                left="44%"
              >
                OR
              </Text>
            </Box>

            <Link to="/auth/redirect" style={{ width: "100%" }}>
              <Button
                variant="outline"
                color="primary"
                borderColor="primary"
                borderRadius="2px"
                width="100%"
                _hover={{
                  background: "transparent",
                }}
              >
                Login with SSO
              </Button>
            </Link>

            <Divider />
          </VStack>
        </form>
      </VStack>
    </Flex>
  );
};

export default Login;
