import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import Theme from './config/Theme.tsx';
import { router } from './routes/web.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ChakraProvider theme={Theme}>
    <RouterProvider router={router} />
  </ChakraProvider>
);
