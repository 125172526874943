import { AxiosError } from "axios";
import { useEffect } from "react";
import { passportCallback, passportValidation } from "../../utils/api/Auth";
import { AuthResponse } from "./Login";

const Callback = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      passportCallback(code)
        .then((data) => {
          const access_token = data?.access_token;
          if (access_token) {
            passportValidation(access_token)
              .then((res) => {
                const response: AuthResponse = res.data;
                localStorage.setItem("user", JSON.stringify(response.user));
                localStorage.setItem("token", response.token);

                window.location.href = "/";
              })
              .catch((err) => {
                console.log(err);
                window.location.href = "/login";
              });
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          window.location.href = "/login";
        });
    } else {
      alert("Undefined code");
    }
  }, []);

  return null;
};

export default Callback;
