import { InformationFormType } from '@features/MRS';
import { AxiosRequestConfig } from 'axios';
import { SessionHandler } from '../SessionHandler';
import ApiConnection from '../connection';
import {
  AttacklistExportProps,
  AttacklistRequestParams,
  ReminderFormPayload,
} from './Attacklist.type';

export const getAttacklist = async (params?: AttacklistRequestParams) => {
  try {
    const token = SessionHandler.getToken();
    if (!token) {
      throw new Error('Failed to retrieve token');
    }

    const axiosConfig: AxiosRequestConfig = {
      method: 'get',
      url: '/attacklist',
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await ApiConnection(axiosConfig);
  } catch (error) {
    console.error('Error fetching attack list:', error);
    throw error;
  }
};

export const getAttacklistDetail = async (attacklistId: number) => {
  try {
    const token = SessionHandler.getToken();
    if (!token) {
      throw new Error('Failed to retrieve token');
    }

    const axiosConfig: AxiosRequestConfig = {
      method: 'get',
      url: `/attacklist/reminder/detail/${attacklistId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await ApiConnection(axiosConfig);
  } catch (error) {
    console.error('Error fetching attack list detail:', error);
    throw error;
  }
};

export const saveReminder = async (form: ReminderFormPayload) => {
  try {
    const token = SessionHandler.getToken();
    if (!token) {
      throw new Error('Failed to retrieve token');
    }

    const axiosConfig: AxiosRequestConfig = {
      method: 'post',
      url: `/attacklist/reminder`,
      data: form,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await ApiConnection(axiosConfig);
  } catch (error) {
    console.error('Error saving reminder:', error);
    throw error;
  }
};

export const updateInformation = async (form: InformationFormType) => {
  try {
    const token = SessionHandler.getToken();
    if (!token) {
      throw new Error('Failed to retrieve token');
    }

    const axiosConfig: AxiosRequestConfig = {
      method: 'put',
      url: `/attacklist/info`,
      data: form,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await ApiConnection(axiosConfig);
  } catch (error) {
    console.error('Error updating information:', error);
    throw error;
  }
};

export const exportAttacklist = async ({
  params,
  filename = 'attacklist_export.csv',
}: AttacklistExportProps) => {
  try {
    const token = SessionHandler.getToken();
    if (!token) {
      throw new Error('Failed to retrieve token');
    }

    const axiosConfig: AxiosRequestConfig = {
      method: 'get',
      url: `/excel/attacklist`,
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    };

    const response = await ApiConnection(axiosConfig);

    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch (error) {
    console.error('Error exporting attacklist:', error);
  }
};

export const downloadGuideline = async () => {
  try {
    const token = SessionHandler.getToken();
    if (!token) {
      throw new Error('Failed to retrieve token');
    }

    const axiosConfig: AxiosRequestConfig = {
      method: 'get',
      url: '/download-guideline',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    };

    const response = await ApiConnection(axiosConfig);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'MRS_Guideline_2024.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response;
  } catch (error) {
    console.error('Error downloading guideline:', error);
    throw error;
  }
};
