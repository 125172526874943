import { UserModel } from '../../../models/UserModel';

export class SessionHandler {
  static setUser(user: UserModel) {
    try {
      const userJSON = JSON.stringify(user);
      if (userJSON) {
        localStorage.setItem('user', userJSON);
      } else {
        throw new Error('User object is empty or invalid.');
      }
    } catch (error) {
      console.error('Error setting user in localStorage:', error);
    }
  }

  static getUser(): UserModel | null {
    try {
      const userString = localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        if (user && typeof user === 'object') {
          return user as UserModel;
        } else {
          throw new Error('User data retrieved from localStorage is invalid.');
        }
      }
    } catch (error) {
      console.error('Error getting user from localStorage:', error);
    }
    return null;
  }

  static setToken(token: string) {
    try {
      if (token) {
        localStorage.setItem('token', token);
      } else {
        throw new Error('Token is empty or invalid.');
      }
    } catch (error) {
      console.error('Error setting token in localStorage:', error);
    }
  }

  static getToken(): string | null {
    try {
      const token = localStorage.getItem('token');
      return token;
    } catch (error) {
      console.error('Error getting token from localStorage:', error);
      return null;
    }
  }

  static clearLocalStorage() {
    try {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    } catch (error) {
      console.error('Error clearing localStorage:', error);
    }
  }
}
