import {
  Avatar,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { memo, useCallback, useState } from 'react';
import { ChevronDown, LogOut, Menu as MenuIcon } from 'react-feather';
import { useSidebar } from '../../../context';
import { requestLogout } from '../../../utils/api/Auth';
import { SessionHandler } from '../../../utils/api/SessionHandler';

const Topbar = () => {
  const { toggleSidebar } = useSidebar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = SessionHandler.getUser();

  const onLogout = useCallback(() => {
    setIsLoading(true);
    requestLogout().finally(() => {
      window.location.href = '/login';
      setIsLoading(false);
    });
  }, []);

  return (
    <Flex
      height="64px"
      width="100%"
      background="white"
      paddingX="15px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #DEDEDE"
      position="sticky"
      top={0}
      zIndex={200}
    >
      <Flex alignItems="center" columnGap="10px">
        <Flex
          as="button"
          onClick={() => (window.location.href = '/')}
          alignItems="center"
          gap="14px"
        >
          <Image
            src="/images/logo.svg"
            alt="logo-app"
            height="34px"
            objectFit="contain"
          />

          <Text fontSize="x-large" fontWeight="700" display="flex">
            MRS<span style={{ color: '#E55254' }}>GENIUS</span>
          </Text>
        </Flex>

        <Button
          variant="unstyled"
          display="flex"
          alignItems="center"
          opacity={0.6}
          onClick={() => toggleSidebar()}
        >
          <MenuIcon size={20} />
        </Button>
      </Flex>

      <Flex columnGap="1em">
        <Menu>
          <MenuButton as="button" background="black">
            <Flex alignItems="center" columnGap="15px">
              <Flex
                columnGap="20px"
                alignItems="center"
                display={{ base: 'none', md: 'flex' }}
              >
                <VStack gap={0} alignItems="end">
                  <Text fontSize="14px" fontWeight="600">
                    {user?.fullname ?? 'Accounts'}
                  </Text>
                  <Text fontSize="12px">{user?.role ?? 'PIC'}</Text>
                </VStack>
                <Avatar
                  size="sm"
                  src="https://dms.toyota.astra.co.id/images/default_profile-icon.png"
                />
              </Flex>
              <ChevronDown size={18} />
            </Flex>
          </MenuButton>
          <MenuList borderRadius="2px" width="fit-content">
            <MenuItem
              as="button"
              icon={isLoading ? <Spinner size="sm" /> : <LogOut size={15} />}
              onClick={onLogout}
              display="flex"
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

const MemoizedTopbar = memo(Topbar);

export { MemoizedTopbar as Topbar };
