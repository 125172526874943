import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useSidebar } from '@context/index';
import { getMenuFromURL, isTablet } from '@utils/helper';
import { useCallback, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { NavInterface } from './Nav.types';
import { NavChild } from './NavChild';

export const Nav = ({ isActive, menu }: NavInterface) => {
  const { setActiveMenu, toggleSidebar } = useSidebar();
  const navigate = useNavigate();

  // -- Convert props to React Components
  const Icon = menu[0]?.icon;

  // -- Accordion Expanding
  const [isExpand, setIsExpand] = useState<boolean>(false);

  // -- Accordion Event Handler
  const onAccordionChange = useCallback(
    (index: number | number[]) => {
      if (Array.isArray(index)) {
        menu[0]?.isExpanded && setIsExpand(index.length === 2 ? true : false);
      }
    },
    [menu]
  );

  const onNavClick = () => {
    if (!menu[0]?.isExpanded) {
      const URL = menu[0]?.link?.toString() ?? '';
      setActiveMenu(getMenuFromURL(URL));

      // -- Move to another page
      navigate(URL);

      if (isTablet()) toggleSidebar();
    }
  };

  const NavComponents = () => {
    return (
      <AccordionButton
        width="100%"
        alignItems="center"
        columnGap="10px"
        background={isActive ? 'primary' : 'transparent'}
        color={isActive ? 'white' : 'black'}
        borderRadius="4px"
        onClick={onNavClick}
        _hover={{
          background: isActive ? 'primary' : '#f2f2f2',
          color: isActive ? 'white' : 'black',
        }}
      >
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" columnGap="10px">
            {Icon && <Icon size="15px" />}
            <Text fontSize="15px" fontWeight="medium">
              {menu[0]?.label}
            </Text>
          </Flex>

          {menu[0]?.isExpanded && (
            <ChevronRight
              size="15px"
              style={{
                transform: `rotate(${isExpand ? '90deg' : '0deg'})`,
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          )}
        </Flex>
      </AccordionButton>
    );
  };

  return (
    <VStack>
      <Accordion
        defaultIndex={[1]}
        allowMultiple
        width="100%"
        gap="10px"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        transitionDuration="0.5s"
        onChange={onAccordionChange}
      >
        <AccordionItem border="none">
          <NavComponents />

          {menu.length > 1 && <NavChild menu={menu} />}
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};
