import { createContext, useContext } from 'react';
import { SidebarContextProps } from './Sidebar.types';

// Define the context
export const SidebarContext = createContext<SidebarContextProps>({
  isOpen: false,
  activeMenu: 'Overview',
  toggleSidebar() {},
  setActiveMenu() {},
});

// Define the hook
export function useSidebar() {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error(
      'useSidebar must be used within a component that is wrapped with SidebarProvider.'
    );
  }

  return context;
}
