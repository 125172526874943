import axios from 'axios';

const ApiConnection = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

ApiConnection.defaults.headers.common['Accept'] = 'application/json';
ApiConnection.defaults.headers.common['app_key'] = import.meta.env.VITE_APP_KEY;

export default ApiConnection;
