import { motion } from 'framer-motion';
import { Suspense } from 'react';
import { SlideDownProps } from './SlideDown.type';

export const SlideDown = ({ children, suspense }: SlideDownProps) => {
  return (
    <motion.div
      key="vehicle-pages"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      {suspense ? <Suspense>{children}</Suspense> : children}
    </motion.div>
  );
};
