export const getMenuFromURL = (URL: string): string => {
  const segments = URL.split('/');
  const activeMenu = segments[1] || 'overview';
  return activeMenu.toString().toLowerCase();
};

export function calculateRatio(a: number, b: number): [number, number] {
  const max = Math.max(a, b);
  const ratioA = a / max;
  const ratioB = b / max;

  if (ratioA === 1 && ratioA === ratioB) {
    return [1, 0.02];
  }

  return [ratioA, 1 - ratioA];
}

export function getQueryParams(url: string): Record<string, string> {
  const params: Record<string, string> = {};

  // Extract the query string from the URL
  const queryString = url.split('?')[1];

  if (queryString) {
    const searchParams = new URLSearchParams(queryString);

    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
  }

  return params;
}

export function calculatePercentage(actual: number, target: number): number {
  if (target === 0 || actual <= 0) {
    return 0;
  }
  const percentage = (actual / target) * 100;
  return parseFloat(percentage.toFixed(2));
}

export function capitalizeFirstChar(s: string): string {
  return (s.charAt(0).toUpperCase() + s.slice(1)) as string;
}
