import { Flex, List } from '@chakra-ui/react';
import { memo } from 'react';

import { v4 } from 'uuid';
import { useSidebar } from '../../../context';
import { SessionHandler } from '../../../utils/api/SessionHandler';
import { SidebarInterface } from './Sidebar.types';
import { Nav, getMenu } from './utils';
import MenuDTO from './utils/Menu/MenuDTO';

const SidebarComponent = (props: SidebarInterface) => {
  const { active } = props;

  const { isOpen } = useSidebar();

  /**
   * -------------------------------------
   * FILTER MENU ACCORDING USER ROLE
   * -------------------------------------
   */

  const user = SessionHandler.getUser();

  const userRole = user?.role;
  const Menus = getMenu(userRole ?? 'MRA').map((item: MenuDTO[]) => {
    return (
      <Nav
        key={v4()}
        menu={item}
        isActive={active?.toLocaleLowerCase() == item[0]?.label.toLowerCase()}
      />
    );
  });

  /**
   * -------------------------------------
   * USER INTERFACE
   * -------------------------------------
   */
  return (
    <Flex
      background="white"
      height={{ base: '100%', lg: 'calc(100vh - 64px)' }}
      width={{ base: isOpen ? '100%' : '0px', lg: isOpen ? '250px' : '0px' }}
      opacity={isOpen ? 1 : 0}
      transition="0.3s"
      direction="column"
      boxSizing="border-box"
      flexGrow={0}
      flexShrink={0}
      position={{ base: 'fixed', lg: 'sticky' }}
      top="64px"
      zIndex={!isOpen ? -1 : 99}
    >
      <List
        display={!isOpen ? 'none' : 'flex'}
        flexDirection="column"
        justifyContent="center"
        padding="10px"
        paddingTop="15px"
        rowGap="5px"
      >
        {Menus}
      </List>
    </Flex>
  );
};

export const Sidebar = memo(SidebarComponent);
